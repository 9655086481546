import React from "react"
import parse from "html-react-parser"
import Seo from "components/Seo"
import SectionPrograms from "components/SectionPrograms"
import PageHeader from "components/PageHeader"
import Section from "components/Section"
import SectionWhyUs from "components/SectionWhyUs"

import SectionContactCta from "components/SectionContactCta"

import TrophyIcon from "images/icons/icon-trophy.png"
import DatabaseIcon from "images/icons/icon-database.png"
import ProfessionalIcon from "images/icons/icon-professional.png"
import KoreanSymbolIcon from "images/icons/icon-korean-symbol.png"

import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { ParallaxBanner, Parallax } from "react-scroll-parallax"
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  StarIcon,
} from "@heroicons/react/solid"
import CaseCarousel from "components/CaseCarousel"
import MemberCarousel from "components/MemberCarousel"
import CaseCard from "components/CaseCard"
import MemeberCard from "components/MemberCard"
import PostCard from "components/PostCard"
import { Fade } from "react-reveal"
import BgContact from "images/bg-contact.jpg"

import Hero from "components/Hero"
import ServicesCollaspe from "components/ServicesCollaspe"

import QuoteStartIcon from "images/icons/icon-quote-start.png"

const CASES = [
  {
    title: "장시간근로개선",
    description:
      "교대제 전환, 유연근무제 등 사업장 여건에 맞는 근로시간 단축 방안 마련",
  },

  {
    title: "장년고용안정체계구축",
    description: "장년 근로자의 고용안정을 위한 인사관리제도 개선",
  },

  {
    title: "고용문화개선",
    description:
      "조직문화 진단을 통한 남녀 모두 일하기 좋은 고용 문화 개선 지원",
  },

  {
    title: "임금체계개선",
    description:
      "직무·능력·역할 중심의 임금체계 개선 등 합리적인 임금체계 개선",
  },

  {
    title: "평가체계개선",
    description:
      "고용, 평가, 승진, 배치전환 등을 위해 공정하고 합리적인 평가체계를 구축하여 인적자원 관리시스템 구축 및 내부공정성 확보",
  },

  {
    title: "노사파트너십체계구축",
    description:
      "노사관계 전략 수립, 상생적 파트너십 기반 구축 및 운영 프로그램 설계 등을 통한 노사상생의 변화 추구",
  },

  {
    title: "작업조직/작업환경 개선",
    description:
      "직무수행자의 역할과 자율 확대, 과업 통합, 참여 확대 등을 통한 생산주체로서의 근로자 현장 책임 경영 구현",
  },

  {
    title: "평생학습체계구축",
    description:
      "경영전략(비전, 목표, 핵심가치 등)에 따른 교육체계 수립, 인재 확보 및 지속 가능한 경영발전 구현",
  },

  {
    title: "안전한 일터 구축",
    description:
      "기업의 작업환경, 보건, 안전실태에 대한 점검 및 안전대응 역량 강화 지원",
  },
]

export default function InnovationProgramPage({ data: { allWpCase } }) {
  const cases = allWpCase?.nodes

  return (
    <div>
      <Seo
        title={"일터혁신"}
        description="협력적 노사관계를 기반으로 근로자의 참여에 의하여 작업조직과 작업방식을 지속적으로 개선함으로써 생산성과 근로생활의 질 향상을 지원합니다."
      />
      <PageHeader
        title={"일터혁신"}
        description={
          "협력적 노사관계를 기반으로 근로자의 참여에 의하여 작업조직과 작업방식을 지속적으로 개선함으로써 생산성과 근로생활의 질 향상을 지원합니다."
        }
      />

      <SectionPrograms source={CASES} />

      <Section>
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8">
            <div className="w-full px-4 lg:px-8">
              <div className="mb-8 text-center">
                <Section.Headline eyebrow={"How to work"}>
                  복잡한 일도 함께하면 손쉽게 가능합니다.
                </Section.Headline>
              </div>
            </div>
            <div className="w-full px-4 lg:px-8">
              <div className="max-w-screen-lg mx-auto ">
                <table className="border-collapse border border-gray-200 table table-auto text-gray-500">
                  <tbody>
                    <tr>
                      <th className="bg-gray-50 border border-gray-200 text-gray-900 p-4 w-2/12">
                        신청자격
                      </th>
                      <td className="border border-gray-200 p-4 lg:p-8">
                        <ul className="list-disc pl-5">
                          <li className="break-words">
                            고용보험에 가입하고, 보험료를 체납하지 않은 사업장
                          </li>
                          <li className="break-words">
                            고용노동부 공고일 기준 최근 3년간 임금체불 이력이
                            없는 사업장 (근로기준법 제43조1항, 동법 시행령
                            제23조 2항에 의해 공표된 사업장은 지원 제한)
                          </li>
                          <li className="break-words">
                            고용노동부 공고일 기준 최근 3년간 산재발생 이력이
                            없는 사업장 (산업안전보건법 제9조2항, 동법 시행령
                            제8조4항 및 시행규칙 제3조3항에 의해 공표된 사업은
                            지원 제한)
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th className="bg-gray-50 border border-gray-200 text-gray-900 p-4">
                        제출서류
                      </th>
                      <td className="border border-gray-200 p-4 lg:p-8">
                        <ul className="list-disc pl-4">
                          <li className="break-words"> 온라인 지원서</li>
                          <li className="break-words">
                            {" "}
                            당해년도 일터혁신 컨설팅 지원사업 신청서
                          </li>
                          <li className="break-words"> 사업자 등록증</li>
                          <li className="break-words">
                            근로자규모 입증 서류 (택 1) - 4대보험 가입자명부
                            (4대사회보험 정보연계센터) 또는 건강보험사업장
                            적용통보서 (국민건강보험공단)
                          </li>
                          <li className="break-words">
                            고용보험 완납증명원 (근로복지공단 문의 또는
                            고용ㆍ산재 토탈서비스 이용 및 발급)
                          </li>
                          <li className="break-words"> 노사대표합의 확인서</li>
                          <li className="break-words"> 정보 활용 동의서</li>
                          <li className="break-words">
                            {" "}
                            스마트 공장 입증 서류 (*스마트공장 사업장인 경우
                            제출)
                          </li>
                          <li className="break-words">
                            - 추가 서류: 정부지원사업 수혜이력, 전자협약서,
                            ERP도입 시스템 계약서, 자체시스템개발 증명자료 등
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th className="bg-gray-50 border border-gray-200 text-gray-900 p-4">
                        신청절차
                      </th>
                      <td className="border border-gray-200 p-4 lg:p-8">
                        작성서류 사전 작성 ➤ 구비서류 준비 ➤ '일터혁신' 홈페이지
                        접속 (www.nosa.or.kr) ➤ 회원가입 ➤ 사업공고 접수 신청 ➤
                        컨설팅기관 'HPC컨설팅' 선택 ➤ 기업 정보 작성 ➤ 최종 신청
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-right pt-3">
                  <a
                    target="_blank"
                    href="https://www.nosa.or.kr/portal/nosa/majorBiz/mGWrc/wrkInnoCnslt"
                    className="text-primary border-b border-primary"
                  >
                    노사발전재단 일터혁신 홈페이지 바로가기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <SectionWhyUs />

      {/* <Section>
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="mb-3 lg:mb-6">
            <div className="flex flex-col lg:flex-row flex-wrap lg:items-end justify-between">
              <div className="mb-4 lg:mb-0">
                <Section.Headline eyebrow={"Case Studies"}>
                  HPC컨설팅과 함께한 회사들의
                  <br className="hidden lg:block" />
                  이야기를 들어보세요.
                </Section.Headline>
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="px-1">
                  <button className="hpc-swiper-button case-carousel-swiper-button-prev">
                    <ArrowNarrowLeftIcon className="w-5 h-5" />
                  </button>
                </div>
                <div className="px-1">
                  <button className="hpc-swiper-button case-carousel-swiper-button-next">
                    <ArrowNarrowRightIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-overflow-container">
          <div className="overflow-container w-full lg:container mx-auto px-4 lg:px-8">
            <CaseCarousel
              source={cases}
              renderItem={item => {
                const { caseCategories, uri } = item
                return (
                  <div className="pt-4 flex  w-full h-full" key={item.id}>
                    <CaseCard
                      url={uri}
                      consultant={item.case.consultant}
                      eyebrow={
                        <ul>
                          {caseCategories.nodes.map(
                            (item, index) =>
                              !index && (
                                <li
                                  key={item.id}
                                  className="text-sm lg:text-base text-primary"
                                >
                                  {item.name}
                                </li>
                              )
                          )}
                        </ul>
                      }
                      title={item.title}
                      featuredImage={
                        <div className="relative overflow-hidden">
                          <div
                            className="bg-gray-200"
                            style={{ paddingBottom: "56.25%" }}
                          ></div>
                          <GatsbyImage
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              inset: 0,
                              objectFit: "cover",
                            }}
                            image={
                              item.featuredImage?.node?.localFile
                                ?.childImageSharp?.gatsbyImageData
                            }
                            alt={item.featuredImage?.node?.alt || ``}
                          />
                        </div>
                      }
                    />
                  </div>
                )
              }}
            />
          </div>
        </div>
        <div className="text-center mt-8">
          <Link
            to="/cases"
            className="transition duration-200 border border-gray-500 text-gray-500 hover:border-primary hover:text-white hover:bg-primary rounded-full px-6 py-2 inline-block"
          >
            성공사례 전체보기
          </Link>
        </div>
      </Section> */}

      <SectionContactCta />
    </div>
  )
}

export const pageQuery = graphql`
  query ProgramInnovationPageQuery {
    allWpCase(
      sort: { fields: [date], order: DESC }
      limit: 9
      filter: {
        caseCategories: { nodes: { elemMatch: { slug: { eq: "innovation" } } } }
      }
    ) {
      nodes {
        id
        date
        slug
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        caseCategories {
          nodes {
            id
            name
            slug
            uri
          }
        }
        case {
          consultant {
            ... on WpConsultant {
              id
              slug
              uri
              title
              consultant {
                role
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        uri
      }
    }
  }
`
